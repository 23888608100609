import { createSlice } from '@reduxjs/toolkit';

// Initial state for FAQ slice
const initialState = {
  section1: {},
};

const termsofuseSlice = createSlice({
  name: 'termsofuse',
  initialState,
  reducers: {
    setTermsOfUseSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const termsofuseActions = termsofuseSlice.actions;
export const termsofuseReducer = termsofuseSlice.reducer;

export const TermsOfUseActionTypes = {
  FETCH_TERMSOFUSE: 'TermsOfUse/Fetch',
  EDIT_TERMSOFUSE: 'TermsOfUse/Edit',
};
