import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const capitalize = (str) => {
  // Replace underscores with spaces
  str = str.replace(/_/g, " ");

  // Capitalize the first letter of each word
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Array is for BreadCrumbs where going back is not allowed.
const specialPaths = [
  "edit",
  "services",
  "about_us",
  "contact_us",
  "themes",
  "projects",
  "careers",
  "home",
];

const BreadcrumbsCard = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const homePath = "/";

  const updateBreadcrumbs = ({ pathname }) => {
    const paths = pathname.split("/").filter((path) => path !== "");

    const breadcrumbsArray = paths.map((path, index) => {
      let label = capitalize(path);
      let link = `/${paths.slice(0, index + 1).join("/")}`;

      if (index === 1) {
        link += homePath;
      } else if (specialPaths.includes(path)) {
        link = `/${paths.slice(0, index + 2).join("/")}`;
        link += homePath;
      }

      return { label, link };
    });

    setBreadcrumbs(breadcrumbsArray);
  };

  useEffect(() => {
    updateBreadcrumbs(location);
  }, [location]);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <Link
          key={breadcrumb.label}
          to={breadcrumb.link}
          className={`breadcrumb${
            index === breadcrumbs.length - 1 ? "style" : "Color"
          }`}
        >
          {breadcrumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsCard;
