import { styled } from "@mui/material/styles";
import style from "styled-components";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

export const DrawerContainer = style.div`
border: 1px solid black;
  
.sidebarLogo {
  width: 50px; 
  margin: 8px 0 0 3px;
}

.sidebarHeader {
  display: flex; 
  flex-direction: row;
}

  .LeftBar{
    .css-0 svg{color:#f47a57; margin:10px;}
    .LeftBarMenu{
      .menuItems {
        text-decoration: none;
        color: white;
        width: 100%;
        padding-top:0px;
        i, svg{color:#f47a57;}
        .MuiListItemText-primary{line-height:1 !important;}
        .MuiListItemButton-root{padding-bottom:5px;padding-top:5px;
        .css-tlelie-MuiListItemText-root{margin:0px;}
        }
      }
      .childrenMenuItems {
        text-decoration: none;
        color: white;
        margin: 0 0 0 80px;
        padding: 3px 0px; display: flex;
        .MuiListItemButton-root{padding:0px !important;
        .MuiListItemText-root {margin:0px;}
        }
      } 
    }
  }
`;

export const AppBarContainer = style.div`
background: linear-gradient(0deg, rgb(62, 62, 62), rgb(0, 0, 0));
*::-webkit-scrollbar {        
  width: 5px;
}
 .toolbar {
  justify-content: space-between;
 }

 .emailDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
 }
`;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(7)} + 1px)`,
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: "linear-gradient(-90deg, rgb(62, 62, 62), rgb(0, 0, 0))",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    background: "linear-gradient(90deg, rgb(62, 62, 62), rgb(0, 0, 0))",
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  // padding: theme.spacing(2, 2),
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "&:hover": {
    width: drawerWidth,
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  },
  ...{
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  },
}));
