import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class HomeService {
  // POST request to fetch Home page section wise
  async getHomeSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "Home",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to edit HomeSection
  async editHomeSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.HOME}/${payload.sectionName}`,
      {
        ...payload.formFields,
      }
    );
    return response;
  }
}

const homeService = new HomeService();
export default homeService;
