import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ContactUsService {
  // POST request to fetch Contact Us page section wise
  async getContactUsSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'ContactUs',
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create Contact Us section
  async editContactUsSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.CONTACT_US}/${payload.sectionName}`,
      {
        ...payload.sectionData,
      }
    );
    return response;
  }
}

const contactUsService = new ContactUsService();
export default contactUsService;
