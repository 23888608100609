import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { alertActions } from '../store/slices/alertSlice';
import { ALERT_TYPES } from '../config/constants';
import privacyService from '../services/PrivacyService';
import {
  PrivacyActionTypes,
  privacyActions,
} from '../store/slices/privacySlice';

// saga handler to fetch Privacy sectionwise and update redux store as per the api payload
export const fetchPrivacySection = function* (action) {
  try {
    const response = yield call(
      privacyService.getPrivacySection,
      action.payload
    );
    const faqResponse = yield get(response, 'data');
    yield put(
      privacyActions.setPrivacySection({
        sectionName: action.payload.sectionName,
        data: faqResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit privacy sectionwise and update redux store as per the api payload
export const editPrivacySection = function* (action) {
  try {
    const response = yield call(
      privacyService.editPrivacySection,
      action.payload
    );
    const editedSectionData = yield get(response, 'data');
    // Assuming the response contains the updated section data
    yield put(
      privacyActions.setPrivacySection({
        sectionName: action.payload.sectionName,
        data: editedSectionData.sectionData,
      })
    );
    // Assuming you want to show a success alert upon successful edit
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Updated Successfully.',
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* privacySaga() {
  yield takeLatest(PrivacyActionTypes.FETCH_PRIVACY, fetchPrivacySection);
  yield takeLatest(PrivacyActionTypes.EDIT_PRIVACY, editPrivacySection);
}
