import { all, call, spawn } from "redux-saga/effects";
import aboutUsSaga from "./aboutUsSaga";
import serviceSaga from "./serviceSaga";
import themesSaga from "./themesSaga";
import careersSaga from "./careersSaga";
import projectSaga from "./projectSaga";
import faqSaga from "./faqSaga";
import termsofuseSaga from "./termsOfUseSaga";
import homeSaga from "./homeSaga";
import privacySaga from "./privacySaga";
import contactUsSaga from "./contactUsSaga";
import blogsSaga from "./blogsSaga";
import imageSaga from "./imageSaga";
import siteInfoSaga from "./siteInfoSaga";

/**
 * add all the saga in the sagas array
 */
const sagas = [
  aboutUsSaga,
  serviceSaga,
  themesSaga,
  careersSaga,
  homeSaga,
  projectSaga,
  faqSaga,
  termsofuseSaga,
  privacySaga,
  contactUsSaga,
  blogsSaga,
  imageSaga,
  siteInfoSaga,
];

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.error("Unexpected saga termination", error);
          }
        }
      })
    )
  );
}
