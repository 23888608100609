import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import store from "./store/store";
import GlobalStyle from "./styles/GlobalStyle";
import { ThemeProvider, createTheme } from "@mui/material";
import { APP_THEME } from "./config/appConfig";

// MUI theme customizations
const theme = createTheme(APP_THEME);

const App = () => {
  return (
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <GlobalStyle />
          <Routes />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
