import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class FaqService {
  // POST request to fetch FAQ page section wise
  async getFaqSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "Faq",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create FaqSection
  async editFaqSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.FAQ}/${payload.sectionName}`,
      {
        ...payload.sectionData,
      }
    );
    return response;
  }
}

const faqService = new FaqService();
export default faqService;
