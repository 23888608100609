import { call, put, takeLatest } from "redux-saga/effects";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";
import siteInfoService from "../services/SiteInfoService";
import { get } from "lodash";
import {
  SiteInfoActionTypes,
  siteInfoActions,
} from "../store/slices/siteInfoSlice";

// saga handler for fetching site info api call
export const fetchSiteInfo = function* (action) {
  try {
    console.log("SAGA");
    const response = yield call(
      siteInfoService.getSiteInfoSection,
      action.payload
    );
    const siteInfoResponse = yield get(response, "data");
    yield put(
      siteInfoActions.setSiteInfo({
        sectionName: action.payload.sectionName,
        data: siteInfoResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler for editing site info api call
export const editSiteInfo = function* (action) {
  try {
    const response = yield call(
      siteInfoService.editSiteInfoSection,
      action.payload
    );
    const siteInfoResponse = yield get(response, "data");
    yield put(
      siteInfoActions.setSiteInfo({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: siteInfoResponse.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* siteInfoSaga() {
  yield takeLatest(SiteInfoActionTypes.FETCH_SITE_INFO, fetchSiteInfo);
  yield takeLatest(SiteInfoActionTypes.EDIT_SITE_INFO, editSiteInfo);
}
