import { call, put, takeLatest } from "redux-saga/effects";
import { ImageActionTypes, imageActions } from "../store/slices/imageSlice";
import imagesService from "../services/ImagesService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to handle images
export const handleImages = function* (action) {
  try {
    const response = yield call(imagesService.manageImages, action.payload);
    const successMessage = yield get(response, "data");
    yield put(
      imageActions.setImages({
        apiEndpoint: action.payload.apiEndpoint,
        data: successMessage.SectionData,
      })
    );
    if (successMessage.Message) {
      yield put(
        alertActions.createAlert({
          type: ALERT_TYPES.SUCCESS,
          message: successMessage.Message,
        })
      );
    }
  } catch (err) {
    const errorMessage = err?.response?.data?.Message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* imageSaga() {
  yield takeLatest(ImageActionTypes.HANDLE_IMAGES, handleImages);
}
