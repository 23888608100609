import { combineReducers } from "@reduxjs/toolkit";
import { loaderReducer } from "./slices/loaderSlice";
import { alertReducer } from "./slices/alertSlice";
import { serviceReducer } from "./slices/serviceSlice";
import { aboutUsReducer } from "./slices/aboutUsSlice";
import { themesReducer } from "./slices/themesSlice";
import { careersReducer } from "./slices/careersSlice";
import { projectReducer } from "./slices/projectSlice";
import { faqReducer } from "./slices/faqSlice";
import { termsofuseReducer } from "./slices/termsofuseSlice";
import { homeReducer } from "./slices/homeSlice";
import { privacyReducer } from "./slices/privacySlice";
import { contactUsReducer } from "./slices/contactUsSlice";
import { blogsReducer } from "./slices/blogsSlice";
import { imageReducer } from "./slices/imageSlice";
import { siteInfoReducer } from "./slices/siteInfoSlice";

const appReducer = combineReducers({
  loader: loaderReducer,
  alert: alertReducer,
  home: homeReducer,
  services: serviceReducer,
  aboutUs: aboutUsReducer,
  projects: projectReducer,
  themes: themesReducer,
  careers: careersReducer,
  faq: faqReducer,
  termsofuse: termsofuseReducer,
  privacy: privacyReducer,
  contactUs: contactUsReducer,
  blogs: blogsReducer,
  images: imageReducer,
  siteInfo: siteInfoReducer,
});

/**
 * To initialize the combined state
 */
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
