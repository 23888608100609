import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AppBar, AppBarContainer } from "./NavBar.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "lodash";
import { Button } from "@mui/material";
import { PAGE_URL } from "../../config/appConfig";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/slices/alertSlice";
import BreadcrumbsCard from "../Breadcrumbs/Breadcrumbs";
const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const email = localStorage.getItem("Email");
  let pageName = capitalize(location.pathname.split("/")[1]);
  pageName = pageName.replace(/_/g, " ");
  pageName = pageName
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");

  if (!pageName || pageName === "Faq") {
    pageName = pageName === "Faq" ? "FAQs" : "Dashboard";
  }

  const handleLogout = () => {
    localStorage.clear();
    dispatch(
      alertActions.createAlert({
        type: "success",
        message: "Logged out successfully!",
      })
    );
    navigate(PAGE_URL.LOGIN);
  };

  return (
    <AppBar position="fixed">
      <AppBarContainer>
        <Toolbar className="toolbar">
          <Typography variant="h6" noWrap component="div">
            {pageName}
          </Typography>
          <div className="emailDiv">
            <p>{email}</p>
            <Button
              style={{ border: "1px solid white", color: "white" }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </Toolbar>
      </AppBarContainer>
      <div className="breadcrumbs">
        <BreadcrumbsCard />
      </div>
    </AppBar>
  );
};

export default TopBar;
