import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class ServicesService {
  // POST request to fetch Service page section wise
  async getServiceSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "Services",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create ServiceSection
  async editServiceSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.SERVICES}/${payload.sectionName}`,
      {
        ...payload.formFields,
      }
    );
    return response;
  }
}

const servicesService = new ServicesService();
export default servicesService;
