import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import { PAGE_URL } from "../config/appConfig";
import PublicRoute from "./PublicRoute.js";
import PrivateRoute from "./PrivateRoute.js";
import CircularProgress from "@mui/material/CircularProgress";

// Lazy import all pages
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard.js"));

// Login page
const Login = lazy(() => import("../pages/Login/Login.js"));

// Signup page
const Signup = lazy(() => import("../pages/Signup/Signup.js"));

// Upload Image page
const UploadImage = lazy(() => import("../pages/UploadImage/UploadImage.js"));

// Home Pages
const HomeMetaSection = lazy(() =>
  import("../pages/Home/MetaSection/MetaSection.js")
);
const HomeSection1 = lazy(() => import("../pages/Home/Section1/Section1.js"));
const HomeSection2 = lazy(() => import("../pages/Home/Section2/Section2.js"));
const HomeSection3 = lazy(() => import("../pages/Home/Section3/Section3.js"));
const HomeSection4 = lazy(() => import("../pages/Home/Section4/Section4.js"));
const HomeSection5 = lazy(() => import("../pages/Home/Section5/Section5.js"));
const HomeSection6 = lazy(() => import("../pages/Home/Section6/Section6.js"));
const HomeSection7 = lazy(() => import("../pages/Home/Section7/Section7.js"));
const AddReview = lazy(() => import("../pages/Home/Section7/AddReview.js"));
const EditReview = lazy(() => import("../pages/Home/Section7/EditReview.js"));
const HomeSection8 = lazy(() => import("../pages/Home/Section8/Section8.js"));

// About us pages
const AboutUsMetaSection = lazy(() =>
  import("../pages/AboutUs/MetaSection/MetaSection.js")
);
const AboutUsSection1 = lazy(() =>
  import("../pages/AboutUs/Section1/Section1")
);
const AboutUsSection2 = lazy(() =>
  import("../pages/AboutUs/Section2/Section2")
);
const AboutUsSection3 = lazy(() =>
  import("../pages/AboutUs/Section3/Section3")
);
const AboutUsSection4 = lazy(() =>
  import("../pages/AboutUs/Section4/Section4")
);
const AboutUsSection5 = lazy(() =>
  import("../pages/AboutUs/Section5/Section5")
);
// const AboutUsSection6 = lazy(() =>
//   import("../pages/AboutUs/Section6/Section6.js")
// );

// Service pages
const ServiceMetaSection = lazy(() =>
  import("../pages/Services/MetaSection/MetaSection.js")
);
const ServiceSection1 = lazy(() =>
  import("../pages/Services/Section1/Section1")
);
const ServiceHome = lazy(() =>
  import("../pages/Services/Section2/ServiceHome.js")
);
const AddService = lazy(() =>
  import("../pages/Services/Section2/AddService.js")
);
const EditService = lazy(() =>
  import("../pages/Services/Section2/EditService.js")
);

//Project pages
const ProjectsMetaSection = lazy(() =>
  import("../pages/Projects/MetaSection/MetaSection.js")
);
const ProjectSection1 = lazy(() =>
  import("../pages/Projects/Section1/ProjectSection1")
);
const ProjectHome = lazy(() =>
  import("../pages/Projects/Section2/ProjectHome")
);
const AddProject = lazy(() => import("../pages/Projects/Section2/AddProject"));
const EditProject = lazy(() =>
  import("../pages/Projects/Section2/EditProject")
);

// Theme Pages
const ThemesMetaSection = lazy(() =>
  import("../pages/Themes/MetaSection/MetaSection.js")
);
const ThemesHomeSection1 = lazy(() =>
  import("../pages/Themes/Section1/Section1.js")
);
const ThemesHomeSection2 = lazy(() =>
  import("../pages/Themes/Section2/ThemesHome")
);
const AddThemeSection2 = lazy(() =>
  import("../pages/Themes/Section2/AddTheme")
);
const EditThemeSection2 = lazy(() =>
  import("../pages/Themes/Section2/EditTheme")
);

// Contact US Page
const ContactUs = lazy(() => import("../pages/ContactUs/Section1/Section1.js"));
const ContactUsMetaSection = lazy(() =>
  import("../pages/ContactUs/MetaSection/MetaSection.js")
);
const ContactUsQueries = lazy(() =>
  import("../pages/ContactUs/Queries/Queries.js")
);
const ContactUsQueryDetails = lazy(() =>
  import("../pages/ContactUs/Queries/QueryDetail.js")
);
const ContactUsFormDropdowns = lazy(() =>
  import("../pages/ContactUs/FormDropdowns/FormDropdowns.js")
);

// Career Pages
const CareersMetaSection = lazy(() =>
  import("../pages/Careers/MetaSection/MetaSection.js")
);
const CareersSection1 = lazy(() =>
  import("../pages/Careers/Section1/Section1")
);
const CareersSection2 = lazy(() =>
  import("../pages/Careers/Section2/Section2")
);
const CareersSection3Home = lazy(() =>
  import("../pages/Careers/Section3/Section3")
);
const CareersSection3Add = lazy(() =>
  import("../pages/Careers/Section3/Section3Add")
);
const CareersSection3Edit = lazy(() =>
  import("../pages/Careers/Section3/Section3Edit")
);
const CareersApplicants = lazy(() =>
  import("../pages/Careers/Applicants/Applicants.js")
);
const CareersApplicantDetails = lazy(() =>
  import("../pages/Careers/Applicants/ApplicantDetails.js")
);

// Blogs Pages
const BlogsMetaSection = lazy(() =>
  import("../pages/Blogs/MetaSection/MetaSection.js")
);
const BlogsSection1 = lazy(() => import("../pages/Blogs/Section1/Section1.js"));
const BlogsSection2Home = lazy(() =>
  import("../pages/Blogs/Section2/Section2.js")
);
const BlogsSection2Add = lazy(() =>
  import("../pages/Blogs/Section2/Section2Add")
);
const BlogsSection2Edit = lazy(() =>
  import("../pages/Blogs/Section2/Section2Edit")
);

// FAQ Page
const FaqHome = lazy(() => import("../pages/Faq/Section1/Section1.js"));
const FaqMetaSection = lazy(() =>
  import("../pages/Faq/MetaSection/MetaSection.js")
);

// PrivacyPolicy Page
const PrivacyPolicyHome = lazy(() =>
  import("../pages/PrivacyPolicy/Section1/Section1.js")
);
const PrivacyPolicyMetaSection = lazy(() =>
  import("../pages/PrivacyPolicy/MetaSection/MetaSection.js")
);

// TermsOfUse Page
const TermsOfUseHome = lazy(() =>
  import("../pages/TermsOfUse/Section1/Section1.js")
);
const TermsOfUseMetaSection = lazy(() =>
  import("../pages/TermsOfUse/MetaSection/MetaSection.js")
);

// SiteInfo Pages
const SiteInfoMetaSection = lazy(() =>
  import("../pages/SiteInfo/MetaSection/MetaSection.js")
);
const SiteInfoPage = lazy(() =>
  import("../pages/SiteInfo/Section1/SiteInfo.js")
);

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div style={{ margin: "47vh 0 0 47vw" }}>
          <CircularProgress />
        </div>
      }
    >
      <Routes>
        <Route
          path={PAGE_URL.LOGIN}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={PAGE_URL.DASHBOARD}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
          index
        />
        <Route
          path={PAGE_URL.SIGNUP}
          element={
            <PrivateRoute>
              <Signup />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.UPLOAD_IMAGE}
          element={
            <PrivateRoute>
              <UploadImage />
            </PrivateRoute>
          }
        />
        {/* Site Info Pages */}
        <Route
          path={PAGE_URL.SITE_INFO}
          element={
            <PrivateRoute>
              <SiteInfoPage />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.SITE_INFO_META_SECTION}
          element={
            <PrivateRoute>
              <SiteInfoMetaSection />
            </PrivateRoute>
          }
        />

        {/* Home Pages */}
        <Route
          path={PAGE_URL.HOME_META_SECTION}
          element={
            <PrivateRoute>
              <HomeMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_1}
          element={
            <PrivateRoute>
              <HomeSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_2}
          element={
            <PrivateRoute>
              <HomeSection2 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_3}
          element={
            <PrivateRoute>
              <HomeSection3 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_4}
          element={
            <PrivateRoute>
              <HomeSection4 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_5}
          element={
            <PrivateRoute>
              <HomeSection5 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_6}
          element={
            <PrivateRoute>
              <HomeSection6 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_7}
          element={
            <PrivateRoute>
              <HomeSection7 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_7_ADD}
          element={
            <PrivateRoute>
              <AddReview />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_7_EDIT}
          element={
            <PrivateRoute>
              <EditReview />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.HOME_SECTION_8}
          element={
            <PrivateRoute>
              <HomeSection8 />
            </PrivateRoute>
          }
        />

        {/* About Us Pages */}
        <Route
          path={PAGE_URL.ABOUT_US_META_SECTION}
          element={
            <PrivateRoute>
              <AboutUsMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.ABOUT_US_SECTION_1}
          element={
            <PrivateRoute>
              <AboutUsSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.ABOUT_US_SECTION_2}
          element={
            <PrivateRoute>
              <AboutUsSection2 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.ABOUT_US_SECTION_3}
          element={
            <PrivateRoute>
              <AboutUsSection3 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.ABOUT_US_SECTION_4}
          element={
            <PrivateRoute>
              <AboutUsSection4 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.ABOUT_US_SECTION_5}
          element={
            <PrivateRoute>
              <AboutUsSection5 />
            </PrivateRoute>
          }
        />
        {/* <Route
          path={PAGE_URL.ABOUT_US_SECTION_6}
          element={
            <PrivateRoute>
              <AboutUsSection6 />
            </PrivateRoute>
          }
        /> */}

        {/* Services Pages */}
        <Route
          path={PAGE_URL.SERVICES_META_SECTION}
          element={
            <PrivateRoute>
              <ServiceMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.SERVICES_SECTION_1}
          element={
            <PrivateRoute>
              <ServiceSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.SERVICES_SECTION_2}
          element={
            <PrivateRoute>
              <ServiceHome />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.SERVICES_SECTION_2_ADD}
          element={
            <PrivateRoute>
              <AddService />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.SERVICES_SECTION_2_EDIT}
          element={
            <PrivateRoute>
              <EditService />
            </PrivateRoute>
          }
        />

        {/* Themes Pages */}
        <Route
          path={PAGE_URL.THEMES_META_SECTION}
          element={
            <PrivateRoute>
              <ThemesMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.THEMES_SECTION_1}
          element={
            <PrivateRoute>
              <ThemesHomeSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.THEMES_SECTION_2}
          element={
            <PrivateRoute>
              <ThemesHomeSection2 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.THEMES_SECTION_2_ADD}
          element={
            <PrivateRoute>
              <AddThemeSection2 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.THEMES_SECTION_2_EDIT}
          element={
            <PrivateRoute>
              <EditThemeSection2 />
            </PrivateRoute>
          }
        />

        {/* Project pages */}
        <Route
          path={PAGE_URL.PROJECTS_META_SECTION}
          element={
            <PrivateRoute>
              <ProjectsMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.PROJECTS_SECTION_1}
          element={
            <PrivateRoute>
              <ProjectSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.PROJECTS_SECTION_2}
          element={
            <PrivateRoute>
              <ProjectHome />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.PROJECTS_SECTION_2_ADD}
          element={
            <PrivateRoute>
              <AddProject />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.PROJECTS_SECTION_2_EDIT}
          element={
            <PrivateRoute>
              <EditProject />
            </PrivateRoute>
          }
        />
        {/* Contact Us */}
        <Route
          path={PAGE_URL.CONTACT_US}
          element={
            <PrivateRoute>
              <ContactUs />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CONTACT_US_META_SECTION}
          element={
            <PrivateRoute>
              <ContactUsMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CONTACT_US_QUERIES}
          element={
            <PrivateRoute>
              <ContactUsQueries />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CONTACT_US_QUERY_DETAILS}
          element={
            <PrivateRoute>
              <ContactUsQueryDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CONTACT_US_FORM_DROPDOWNS}
          element={
            <PrivateRoute>
              <ContactUsFormDropdowns />
            </PrivateRoute>
          }
        />
        {/* Careers Pages */}
        <Route
          path={PAGE_URL.CAREER_META_SECTION}
          element={
            <PrivateRoute>
              <CareersMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_SECTION_1}
          element={
            <PrivateRoute>
              <CareersSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_SECTION_2}
          element={
            <PrivateRoute>
              <CareersSection2 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_SECTION_3}
          element={
            <PrivateRoute>
              <CareersSection3Home />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_SECTION_3_ADD}
          element={
            <PrivateRoute>
              <CareersSection3Add />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_SECTION_3_EDIT}
          element={
            <PrivateRoute>
              <CareersSection3Edit />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_APPLICANTS}
          element={
            <PrivateRoute>
              <CareersApplicants />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.CAREER_APPLICANT_DETAILS}
          element={
            <PrivateRoute>
              <CareersApplicantDetails />
            </PrivateRoute>
          }
        />

        {/* Blogs Pages */}
        <Route
          path={PAGE_URL.BLOGS_META_SECTION}
          element={
            <PrivateRoute>
              <BlogsMetaSection />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.BLOGS_SECTION_1}
          element={
            <PrivateRoute>
              <BlogsSection1 />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.BLOGS_SECTION_2_HOME}
          element={
            <PrivateRoute>
              <BlogsSection2Home />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.BLOGS_SECTION_2_ADD}
          element={
            <PrivateRoute>
              <BlogsSection2Add />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.BLOGS_SECTION_2_EDIT}
          element={
            <PrivateRoute>
              <BlogsSection2Edit />
            </PrivateRoute>
          }
        />

        {/* FAQ Page */}
        <Route
          path={PAGE_URL.FAQ_HOME}
          element={
            <PrivateRoute>
              <FaqHome />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.FAQ_META_SECTION}
          element={
            <PrivateRoute>
              <FaqMetaSection />
            </PrivateRoute>
          }
        />
        {/* Terms Of Use Page */}
        <Route
          path={PAGE_URL.TERMS_OF_USE_HOME}
          element={
            <PrivateRoute>
              <TermsOfUseHome />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.TERMS_OF_USE_META_SECTION}
          element={
            <PrivateRoute>
              <TermsOfUseMetaSection />
            </PrivateRoute>
          }
        />
        {/* Privacy Policy Page */}
        <Route
          path={PAGE_URL.PRIVACY_POLICY_HOME}
          element={
            <PrivateRoute>
              <PrivacyPolicyHome />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_URL.PRIVACY_POLICY_META_SECTION}
          element={
            <PrivateRoute>
              <PrivacyPolicyMetaSection />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
