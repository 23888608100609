import { call, put, takeLatest } from "redux-saga/effects";
import {
  AboutUsActionTypes,
  aboutUsActions,
} from "../store/slices/aboutUsSlice";
import aboutUsService from "../services/AboutUsService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch about us sectionwise and update redux store as per the api payload
export const fetchAboutUsSection = function* (action) {
  try {
    const response = yield call(
      aboutUsService.getAboutUsSection,
      action.payload
    );
    const aboutUsResponse = yield get(response, "data");
    yield put(
      aboutUsActions.setAboutUsSection({
        sectionName: action.payload.sectionName,
        data: aboutUsResponse.SectionTitle2
          ? aboutUsResponse
          : aboutUsResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit about us sectionwise and update redux store as per the api payload
export const editAboutUsSection = function* (action) {
  try {
    const response = yield call(
      aboutUsService.editAboutUsSection,
      action.payload
    );
    const editedSectionData = yield get(response, "data");
    // Assuming the response contains the updated section data
    yield put(
      aboutUsActions.setAboutUsSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    // Assuming you want to show a success alert upon successful edit
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: editedSectionData.FlagMsg || "Data updated successfully!",
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* aboutUsSaga() {
  yield takeLatest(AboutUsActionTypes.FETCH_ABOUT_US, fetchAboutUsSection);
  yield takeLatest(AboutUsActionTypes.EDIT_ABOUT_US, editAboutUsSection);
}
