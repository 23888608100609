import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PAGE_URL } from "../config/appConfig";

const PublicRoute = ({ children }) => {
  if (localStorage.getItem("token")) {
    return <Navigate to={PAGE_URL.DASHBOARD} />;
  } else {
    return <>{children ? children : <Outlet />}</>;
  }
};

export default PublicRoute;
