import { createSlice } from '@reduxjs/toolkit';

// Initial state for FAQ slice
const initialState = {
  section1: {},
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaqSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const faqActions = faqSlice.actions;
export const faqReducer = faqSlice.reducer;

export const FaqActionTypes = {
  FETCH_FAQ: 'Faq/Fetch',
  EDIT_FAQ: 'Faq/Edit',
};
