import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  section1: {},
  section2: {},
  section3: {},
  applications: {},
};

const careersSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {
    setCareersSection: (state, action) => {
      const { payload } = action;
      const { sectionName, data } = payload;

      if (sectionName === "section3") {
        if (!Array.isArray(data.SectionData)) {
          state[sectionName] = {
            ...state[sectionName],
            SectionTitle_Section3: data.SectionTitle_Section3,
            SectionData: [data.SectionData],
          };
        } else {
          state[sectionName] = {
            ...state[sectionName],
            SectionTitle_Section3: data.SectionTitle_Section3,
            SectionData: data.SectionData,
          };
        }
      } else {
        state[sectionName] = data;
      }
    },
  },
});

export const careersActions = careersSlice.actions;
export const careersReducer = careersSlice.reducer;

export const CareersActionTypes = {
  FETCH_CAREERS: "Careers/Fetch",
  EDIT_CAREERS: "Careers/Edit",
};
