import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ProjectService {
  // POST request to fetch projects page section wise
  async getProjectSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'Projects',
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create project section
  async editProjectSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.PROJECTS}/${payload.sectionName}`,
      {
        ...payload.sectionData,
      }
    );
    return response;
  }
}

const projectService = new ProjectService();
export default projectService;
