import { EDIT_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class ImagesService {
  // POST request to manage images
  async manageImages(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.IMAGES}/${payload.apiEndpoint}`,
      {
        ...payload.formFields,
      }
    );
    return response;
  }
}

const imagesService = new ImagesService();
export default imagesService;
