import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class CareersService {
  // POST request to fetch Career page section wise
  async getCareersSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "Careers",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create CareersSection
  async editCareersSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.CAREERS}/${payload.sectionName}`,
      {
        ...payload.formFields,
      }
    );
    return response;
  }
}

const careersService = new CareersService();
export default careersService;
