import { Snackbar } from "@mui/material";
import {
  StyledContainer,
  StyledContent,
  StyledLayoutWrapper,
} from "../../styles/GlobalStyle";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { alertActions } from "../../store/slices/alertSlice";
import { ALERT_CLOSE } from "../../config/appConfig";
import Navbar from "../NavBar/NavBar";


const Layout = ({ children }) => {
  const { type, message } = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  // Handle alert close
  const handleAlertClose = () => dispatch(alertActions.clearAlert());

  return (
    <StyledLayoutWrapper>
      <StyledContainer>
        <StyledContent>
          <Navbar />
          <Snackbar
            open={message.length !== 0}
            autoHideDuration={ALERT_CLOSE}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleAlertClose}
              severity={type}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <div className="insideContainer">{children}</div>
        </StyledContent>
      </StyledContainer>
    </StyledLayoutWrapper>
  );
};

export default Layout;
