import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class ThemesService {
  // POST request to fetch About Us page section wise
  async getThemesSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "Themes",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create themesSection
  async editThemesSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.THEMES}/${payload.sectionName}`,
      {
        ...payload.sectionData,
      }
    );
    return response;
  }
}

const themesService = new ThemesService();
export default themesService;
