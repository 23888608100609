import { call, put, takeLatest } from "redux-saga/effects";
import { HomeActionTypes, homeActions } from "../store/slices/homeSlice";
import homeService from "../services/HomeService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch home sectionwise and update redux store as per the api payload
export const fetchHomeSection = function* (action) {
  try {
    const response = yield call(homeService.getHomeSection, action.payload);
    const homeResponse = yield get(response, "data");
    yield put(
      homeActions.setHomeSection({
        sectionName: action.payload.sectionName,
        data:
          homeResponse.Title_Section1 ||
          homeResponse.Title_Section5 ||
          homeResponse.Title_Section7
            ? homeResponse
            : homeResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit home sectionwise and update redux store as per the api payload
export const editHomeSection = function* (action) {
  try {
    const response = yield call(homeService.editHomeSection, action.payload);
    const editedSectionData = yield get(response, "data");
    yield put(
      homeActions.setHomeSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: editedSectionData.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* homeSaga() {
  yield takeLatest(HomeActionTypes.FETCH_HOME, fetchHomeSection);
  yield takeLatest(HomeActionTypes.EDIT_HOME, editHomeSection);
}
