import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  section1: {},
  section2: {},
  section3: {},
  section4: {},
  section5: {},
  section6: {},
  section7: {},
  section8: {},
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeSection: (state, action) => {
      const { payload } = action;
      const { sectionName, data } = payload;

      if (sectionName === "section7") {
        if (!Array.isArray(data.SectionData)) {
          state[sectionName] = {
            ...state[sectionName],
            Title_Section7: data.Title_Section7,
            SectionData: [data.SectionData],
          };
        } else {
          state[sectionName] = {
            ...state[sectionName],
            Title_Section7: data.Title_Section7,
            SectionData: data.SectionData,
          };
        }
      } else {
        state[sectionName] = data;
      }
    },
  },
});

export const homeActions = homeSlice.actions;
export const homeReducer = homeSlice.reducer;

export const HomeActionTypes = {
  FETCH_HOME: "Home/Fetch",
  EDIT_HOME: "Home/Edit",
};
