import { call, put, takeLatest } from "redux-saga/effects";
import { ThemesActionTypes, themesActions } from "../store/slices/themesSlice";
import themesService from "../services/ThemesServices";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch about us sectionwise and update redux store as per the api payload
export const fetchThemesSection = function* (action) {
  try {
    const response = yield call(themesService.getThemesSection, action.payload);
    const aboutUsResponse = yield get(response, "data");
    yield put(
      themesActions.setThemesSection({
        sectionName: action.payload.sectionName,
        data: aboutUsResponse.SectionTitle_Section2
          ? aboutUsResponse
          : aboutUsResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit about us sectionwise and update redux store as per the api payload

export const editThemesSection = function* (action) {
  try {
    const response = yield call(
      themesService.editThemesSection,
      action.payload
    );
    const editedSectionData = yield get(response, "data");
    // Assuming the response contains the updated section data
    yield put(
      themesActions.setThemesSection({
        sectionName: action.payload.sectionName,
        data: editedSectionData.sectionData,
      })
    );
    // Assuming you want to show a success alert upon successful edit
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: "Updated Successfully.",
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* aboutUsSaga() {
  yield takeLatest(ThemesActionTypes.FETCH_THEMES, fetchThemesSection);
  yield takeLatest(ThemesActionTypes.EDIT_THEMES, editThemesSection);
}
