import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  section1: {},
  section2: {},
};

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogsSection: (state, action) => {
      const { payload } = action;
      const { sectionName, data } = payload;

      if (sectionName === "section2") {
        if (!Array.isArray(data.SectionData)) {
          state[sectionName] = {
            ...state[sectionName],
            Title_Section2: data.Title_Section2,
            SectionData: [data.SectionData],
          };
        } else {
          state[sectionName] = {
            ...state[sectionName],
            Title_Section2: data.Title_Section2,
            SectionData: data.SectionData,
          };
        }
      } else {
        state[sectionName] = data;
      }
    },
  },
});

export const blogsActions = blogsSlice.actions;
export const blogsReducer = blogsSlice.reducer;

export const BlogsActionTypes = {
  FETCH_BLOGS: "Blogs/Fetch",
  EDIT_BLOGS: "Blogs/Edit",
};
