import { createSlice } from '@reduxjs/toolkit';

// Initial state for Privacy slice
const initialState = {
  section1: {},
};

const privacySlice = createSlice({
  name: 'privacy',
  initialState,
  reducers: {
    setPrivacySection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const privacyActions = privacySlice.actions;
export const privacyReducer = privacySlice.reducer;

export const PrivacyActionTypes = {
  FETCH_PRIVACY: 'Privacy/Fetch',
  EDIT_PRIVACY: 'Privacy/Edit',
};
