import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PAGE_URL } from "../config/appConfig";
import Layout from "../components/Layout/Layout";

const PrivateRoute = ({ children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={PAGE_URL.LOGIN} />;
  } else {
    return <Layout>{children ? children : <Outlet />} </Layout>;
  }
};

export default PrivateRoute;
