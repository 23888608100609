import { createSlice } from "@reduxjs/toolkit";

// Initial state for contact us slice
const initialState = {
  section1: {},
  queries: {},
  location: {},
};

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    setContactUsSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const contactUsActions = contactUsSlice.actions;
export const contactUsReducer = contactUsSlice.reducer;

export const ContactUsActionTypes = {
  FETCH_CONTACT_US: "ContactUs/Fetch",
  EDIT_CONTACT_US: "ContactUs/Edit",
};
