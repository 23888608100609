import styled, { createGlobalStyle } from "styled-components";
import { DEVICES } from "../config/appConfig";

export default createGlobalStyle`
  #root { 
    width: 100%;
  }
  * {
        scrollbar-width: thin;}
  html {
    font-size: 16px;
    scroll-behavior: smooth;

  }

  body {
    color: #222222;
    background: linear-gradient(-180deg, rgb(62, 62, 62), rgb(0, 0, 0)) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    scrollbar-width: auto;
scrollbar-width: thin;
scrollbar-width: none;
  }

  .ImgPopArea{
   width:100%;
   display:block;overflow:hidden;
   text-align:center;
  }
  .ImgPopArea img{
    width:auto; 
    height:180px
  }
.pt-5px {
  padding-top: 5px !important;
}
.pt-10px {
  padding-top: 10px !important;
}
.pt-15px {
  padding-top: 15px !important;
}
.pt-20px {
  padding-top: 20px !important;
}
.pt-25px {
  padding-top: 25px !important;
}
.pt-30px {
  padding-top: 30px !important;
}
.pt-35px {
  padding-top: 35px !important;
}
.pt-40px {
  padding-top: 40px !important;
}
.pt-45px {
  padding-top: 45px !important;
}
.pt-50px {
  padding-top: 50px !important;
}
.pt-55px {
  padding-top: 55px !important;
}
.pt-60px {
  padding-top: 60px !important;
}
.pt-65px {
  padding-top: 65px !important;
}
.pt-70px {
  padding-top: 70px !important;
}

.pr-5px {
  padding-right: 5px !important;
}
.pr-10px {
  padding-right: 10px !important;
}
.pr-15px {
  padding-right: 15px !important;
}
.pr-20px {
  padding-right: 20px !important;
}
.pr-25px {
  padding-right: 25px !important;
}
.pr-30px {
  padding-right: 30px !important;
}
.pr-35px {
  padding-right: 35px !important;
}
.pr-40px {
  padding-right: 40px !important;
}
.pr-45px {
  padding-right: 45px !important;
}
.pr-50px {
  padding-right: 50px !important;
}
.pr-55px {
  padding-right: 55px !important;
}
.pr-60px {
  padding-right: 60px !important;
}
.pr-65px {
  padding-right: 65px !important;
}
.pr-70px {
  padding-right: 70px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}
.pb-10px {
  padding-bottom: 10px !important;
}
.pb-15px {
  padding-bottom: 15px !important;
}
.pb-20px {
  padding-bottom: 20px !important;
}
.pb-25px {
  padding-bottom: 25px !important;
}
.pb-30px {
  padding-bottom: 30px !important;
}
.pb-35px {
  padding-bottom: 35px !important;
}
.pb-40px {
  padding-bottom: 40px !important;
}
.pb-45px {
  padding-bottom: 45px !important;
}
.pb-50px {
  padding-bottom: 50px !important;
}
.pb-55px {
  padding-bottom: 55px !important;
}
.pb-60px {
  padding-bottom: 60px !important;
}
.pb-65px {
  padding-bottom: 65px !important;
}
.pb-70px {
  padding-bottom: 70px !important;
}

.pl-5px {
  padding-left: 5px !important;
}
.pl-10px {
  padding-left: 10px !important;
}
.pl-15px {
  padding-left: 15px !important;
}
.pl-20px {
  padding-left: 20px !important;
}
.pl-25px {
  padding-left: 25px !important;
}
.pl-30px {
  padding-left: 30px !important;
}
.pl-35px {
  padding-left: 35px !important;
}
.pl-40px {
  padding-left: 40px !important;
}
.pl-45px {
  padding-left: 45px !important;
}
.pl-50px {
  padding-left: 50px !important;
}
.pl-55px {
  padding-left: 55px !important;
}
.pl-60px {
  padding-left: 60px !important;
}
.pl-65px {
  padding-left: 65px !important;
}
.pl-70px {
  padding-left: 70px !important;
}
  .HeaderLogo{height:70px;}
  .LoginPageLogo{}
  .LoginBg{display:flex; justify-content:center; align-items:center;height:100vh;}
  .DashCardTitle{display:flex; justify-content:space-between; padding-bottom:0px !important;align-items:center; text-transform:uppercase;}
  .DashCardTitle img{opacity:0.4;}
  .DashCardLink{padding-top:0px !important;}
  .DashCardTitle a{padding:0px !important;color:#f47a57;text-transform:capitalize;}
`;

export const StyledLayoutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledContent = styled.div`
  min-height: 450px;
  width: 96%;
  background: #f8f8f8;
  .InputRows {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    margin: 10px 0 20px 0;
  }

  .MuiButton-containedPrimary {
    border-radius: 4px;
    border: 1px solid #676767;
    background: #676767;
    color: white;
  }

  .MuiButton-containedPrimary:hover {
    border-radius: 4px;
    border: 1px solid #67676720;
    background: #67676720;
    color: #000;
  }

  .inputRow {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin: 10px 0px;
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 10px !important;
  }
  .insideContainer {
    width: inherit;
    margin: 130px 30px 20px 30px;
  }
  .breadcrumbs {
    padding: 10px 0 14px 20px;
    background: #f47a57;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .breadcrumbColor {
    text-decoration: none;
    color: black; /* Default color for breadcrumbs */
  }

  .breadcrumb.style {
    color: blue; /* Color for the last breadcrumb */
  }

  .sectionTitle {
    font-size: 20px;
    margin: 20px 0;
  }

  .d-flex-space-between {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .d-flex-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .d-flex-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  @media ${DEVICES.tablet} {
    width: 92%;

    .insideContainer {
      width: inherit;
      margin: 20px 0 0 30px;
    }

    .breadcrumbs {
      margin: 80px 0 0 30px;
    }
  }
  @media ${DEVICES.mobile} {
    width: 88%;

    .insideContainer {
      width: inherit;
      margin: 20px 0 0 25px;
    }

    .breadcrumbs {
      margin: 80px 0 0 25px;
    }
  }
`;
