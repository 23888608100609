// Keep application related configs in this file

// all the page urls of the website
export const PAGE_URL = {
  DASHBOARD: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORBIDDEN: "/forbidden",
  UPLOAD_IMAGE: "/upload_image",

  // Site Information
  SITE_INFO: "/site_Information",
  SITE_INFO_META_SECTION: "/site_Information/metaSection",

  // Home Pages
  HOME_META_SECTION: "/home/metaSection",
  HOME_SECTION_1: "/home/header",
  HOME_SECTION_2: "/home/what_we_offer",
  HOME_SECTION_3: "/home/messages",
  HOME_SECTION_4: "/home/our_projects",
  HOME_SECTION_5: "/home/work_stages",
  HOME_SECTION_6: "/home/wall_of_honour",
  HOME_SECTION_7: "/home/reviews",
  HOME_SECTION_7_ADD: "/home/reviews/add",
  HOME_SECTION_7_EDIT: "/home/reviews/edit/:reviewId",
  HOME_SECTION_8: "/home/track_records",

  // About Us Pages
  ABOUT_US_META_SECTION: "/about_us/metaSection",
  ABOUT_US_SECTION_1: "/about_us/header",
  ABOUT_US_SECTION_2: "/about_us/about_us",
  ABOUT_US_SECTION_3: "/about_us/our_philosophy",
  ABOUT_US_SECTION_4: "/about_us/vision_&_values",
  ABOUT_US_SECTION_5: "/about_us/the_specialist",
  // ABOUT_US_SECTION_6: "/about_us/our_brochures",

  // Service pages
  SERVICES_META_SECTION: "/services/metaSection",
  SERVICES_SECTION_1: "/services/header",
  SERVICES_SECTION_2: "/services/edit_services",
  SERVICES_SECTION_2_ADD: "/services/edit_services/add",
  SERVICES_SECTION_2_EDIT: "/services/edit_services/edit/:serviceId",

  // Themes Pages
  THEMES_META_SECTION: "/themes/metaSection",
  THEMES_SECTION_1: "/themes/header",
  THEMES_SECTION_2: "/themes/edit_themes",
  THEMES_SECTION_2_ADD: "/themes/edit_themes/add",
  THEMES_SECTION_2_EDIT: "/themes/edit_themes/edit/:themeId",
  THEMES_SECTION_2_DELETE: "/themes/edit_themes/delete",

  // Career Pages
  CAREER_META_SECTION: "/careers/metaSection",
  CAREER_SECTION_1: "/careers/header",
  CAREER_SECTION_2: "/careers/benefits",
  CAREER_SECTION_3: "/careers/open_positions",
  CAREER_SECTION_3_ADD: "/careers/open_positions/add",
  CAREER_SECTION_3_EDIT: "/careers/open_positions/edit/:jobId",
  CAREER_APPLICANTS: "/careers/applicants",
  CAREER_APPLICANT_DETAILS: "/careers/applicants/:applicantId",

  // Project pages
  PROJECTS_META_SECTION: "/projects/metaSection",
  PROJECTS_SECTION_1: "/projects/header",
  PROJECTS_SECTION_2: "/projects/edit_projects",
  PROJECTS_SECTION_2_ADD: "/projects/edit_projects/add",
  PROJECTS_SECTION_2_EDIT: "/projects/edit_projects/edit/:projectId",

  // Contact Us page
  CONTACT_US: "/contact_us/edit_contact_us",
  CONTACT_US_QUERIES: "/contact_us/queries",
  CONTACT_US_QUERY_DETAILS: "/contact_us/queries/:queryId",
  CONTACT_US_FORM_DROPDOWNS: "/contact_us/form_dropdowns",
  CONTACT_US_META_SECTION: "/contact_us/metaSection",

  // Blog pages
  BLOGS_META_SECTION: "/blogs/metaSection",
  BLOGS_SECTION_1: "/blogs/header",
  BLOGS_SECTION_2_HOME: "/blogs/edit_blogs",
  BLOGS_SECTION_2_ADD: "/blogs/edit_blogs/add",
  BLOGS_SECTION_2_EDIT: "/blogs/edit_blogs/edit/:blogId",

  // FAQ Page
  FAQ_HOME: "/faq/edit_FAQs",
  FAQ_META_SECTION: "/faq/metaSection",

  // Privacy Policy Page
  PRIVACY_POLICY_META_SECTION: "/privacy_policy/metaSection",
  PRIVACY_POLICY_HOME: "/privacy_policy/edit_privacy_policy",

  // Terms of Use Page
  TERMS_OF_USE_META_SECTION: "/terms_of_use/metaSection",
  TERMS_OF_USE_HOME: "/terms_of_use/edit_terms_of_use",
};

// device related details for responsiveness
export const DEVICE_SIZES = {
  tablet: 768,
  laptop: 992,
  desktop: 1200,
};

export const DEVICE_TYPE = {
  MOBILE: "MOBILE",
  TABLET: "TABLET",
  LAPTOP: "LAPTOP",
  DESKTOP: "DESKTOP",
};

export const DEVICES = {
  sMobile: `(max-width: 480px)`,
  mobile: `(max-width: ${DEVICE_SIZES.tablet}px)`,
  tablet: `(min-width: ${DEVICE_SIZES.tablet}px) and (max-width:${DEVICE_SIZES.laptop}px)`,
  laptop: `(min-width: ${DEVICE_SIZES.laptop}px) and (max-width:${DEVICE_SIZES.desktop}px)`,
  desktop: `(min-width: ${DEVICE_SIZES.desktop}px)`,
};

// Time in milliseconds after which alert will disappear
export const ALERT_CLOSE = 6000;

// MUI theme customizations
export const APP_THEME = {};

// Config for TinyMCE editor
export const tinyMceConfig = {
  menubar: false,
  placeholder: "Description Text *",
  height: 500,
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ],
  toolbar:
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
};
