import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class SiteInfoService {
  // POST request to fetch SiteInfo section wise
  async getSiteInfoSection(payload) {
    console.log("SERVICE");
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "SiteInfo",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to edit SiteInfo section wise
  async editSiteInfoSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.SITE_INFO}/${payload.sectionName}`,
      {
        ...payload.sectionData,
      }
    );
    return response;
  }
}

const siteInfoService = new SiteInfoService();
export default siteInfoService;
