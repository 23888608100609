import { PAGE_URL } from "../../config/appConfig";
import InfoIcon from "@mui/icons-material/Info";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DesignServicesRoundedIcon from "@mui/icons-material/DesignServicesRounded";
import ContrastIcon from "@mui/icons-material/Contrast";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import FeedIcon from "@mui/icons-material/Feed";
import PolicyIcon from "@mui/icons-material/Policy";
import QuizIcon from "@mui/icons-material/Quiz";
import GavelIcon from "@mui/icons-material/Gavel";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import SaveIcon from "@mui/icons-material/Save";

// Sidebar menu data
export const sideBarData = [
  {
    label: "Dashboard",
    icon: DashboardRoundedIcon,
    href: PAGE_URL.DASHBOARD,
  },
  {
    label: "Home",
    icon: HomeRoundedIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.HOME_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.HOME_SECTION_1,
      },
      {
        label: "What We Offer",
        href: PAGE_URL.HOME_SECTION_2,
      },
      {
        label: "Messages",
        href: PAGE_URL.HOME_SECTION_3,
      },
      {
        label: "Our Projects",
        href: PAGE_URL.HOME_SECTION_4,
      },
      {
        label: "Work Stages",
        href: PAGE_URL.HOME_SECTION_5,
      },
      {
        label: "Wall Of Honour",
        href: PAGE_URL.HOME_SECTION_6,
      },
      {
        label: "Reviews",
        href: PAGE_URL.HOME_SECTION_7,
      },
      {
        label: "Track Records",
        href: PAGE_URL.HOME_SECTION_8,
      },
    ],
  },
  {
    label: "About Us",
    icon: InfoIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.ABOUT_US_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.ABOUT_US_SECTION_1,
      },
      {
        label: "About Us",
        href: PAGE_URL.ABOUT_US_SECTION_2,
      },
      {
        label: "Our Philosophy",
        href: PAGE_URL.ABOUT_US_SECTION_3,
      },
      {
        label: "Vision & Values",
        href: PAGE_URL.ABOUT_US_SECTION_4,
      },
      {
        label: "The Specialist",
        href: PAGE_URL.ABOUT_US_SECTION_5,
      },
      // {
      //   label: "Our Brochures",
      //   href: PAGE_URL.ABOUT_US_SECTION_6,
      // },
    ],
  },
  {
    label: "Services",
    icon: HandshakeIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.SERVICES_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.SERVICES_SECTION_1,
      },
      {
        label: "Edit Services",
        href: PAGE_URL.SERVICES_SECTION_2,
      },
    ],
  },
  {
    label: "Projects",
    icon: DesignServicesRoundedIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.PROJECTS_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.PROJECTS_SECTION_1,
      },
      {
        label: "Edit Projects",
        href: PAGE_URL.PROJECTS_SECTION_2,
      },
    ],
  },
  {
    label: "Themes",
    icon: ContrastIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.THEMES_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.THEMES_SECTION_1,
      },
      {
        label: "Edit Themes",
        href: PAGE_URL.THEMES_SECTION_2,
      },
    ],
  },
  {
    label: "Careers",
    icon: WorkRoundedIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.CAREER_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.CAREER_SECTION_1,
      },
      {
        label: "Benefits",
        href: PAGE_URL.CAREER_SECTION_2,
      },
      {
        label: "Open Positions",
        href: PAGE_URL.CAREER_SECTION_3,
      },
      {
        label: "Applicants",
        href: PAGE_URL.CAREER_APPLICANTS,
      },
    ],
  },
  {
    label: "Contact Us",
    icon: CallRoundedIcon,
    children: [
      {
        label: "Meta Section",
        href: PAGE_URL.CONTACT_US_META_SECTION,
      },
      {
        label: "Edit ContactUs",
        href: PAGE_URL.CONTACT_US,
      },
      {
        label: "Form Dropdowns",
        href: PAGE_URL.CONTACT_US_FORM_DROPDOWNS,
      },
      {
        label: "Queries",
        href: PAGE_URL.CONTACT_US_QUERIES,
      },
    ],
  },
  {
    label: "Blogs",
    icon: FeedIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.BLOGS_META_SECTION },
      {
        label: "Header",
        href: PAGE_URL.BLOGS_SECTION_1,
      },
      {
        label: "Edit Blogs",
        href: PAGE_URL.BLOGS_SECTION_2_HOME,
      },
    ],
  },
  {
    label: "Terms of Use",
    icon: GavelIcon,
    children: [
      {
        label: "Meta Section",
        href: PAGE_URL.TERMS_OF_USE_META_SECTION,
      },
      {
        label: "Edit Terms Of Use",
        href: PAGE_URL.TERMS_OF_USE_HOME,
      },
    ],
  },
  {
    label: "Privacy Policy",
    icon: PolicyIcon,
    children: [
      {
        label: "Meta Section",
        href: PAGE_URL.PRIVACY_POLICY_META_SECTION,
      },
      {
        label: "Edit Privacy Policy",
        href: PAGE_URL.PRIVACY_POLICY_HOME,
      },
    ],
  },
  {
    label: "FAQs",
    icon: QuizIcon,
    children: [
      {
        label: "Meta Section",
        href: PAGE_URL.FAQ_META_SECTION,
      },
      {
        label: "Edit FAQs",
        href: PAGE_URL.FAQ_HOME,
      },
    ],
  },
  {
    label: "Site Information",
    icon: SaveIcon,
    children: [
      { label: "Meta Section", href: PAGE_URL.SITE_INFO_META_SECTION },
      { label: "Edit Site Info", href: PAGE_URL.SITE_INFO },
    ],
  },
  {
    label: "Upload Images",
    icon: AddPhotoAlternateRoundedIcon,
    href: PAGE_URL.UPLOAD_IMAGE,
  },
];
