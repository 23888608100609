import { createSlice } from "@reduxjs/toolkit";

// Initial state for blogs slice
const initialState = {
  section1: {},
  section2: {},
};

const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    setThemesSection: (state, action) => {
      const { payload } = action;
      const {sectionName, data} = payload;
      if (sectionName === "section2") {
        if (!Array.isArray(data.SectionData)) {
          state[sectionName] = {
            ...state[sectionName],
            SectionTitle_Section2: data.SectionTitle_Section2,
            SectionData: [data.SectionData],
          };
        } else {
          state[sectionName] = {
            ...state[sectionName],
            SectionTitle_Section2: data.SectionTitle_Section2,
            SectionData: data.SectionData,
          };
        }
      } else {
        state[sectionName] = data;
      }
    },
  },
});

export const themesActions = themesSlice.actions;
export const themesReducer = themesSlice.reducer;

export const ThemesActionTypes = {
  FETCH_THEMES: "Themes/Fetch",
  EDIT_THEMES: "Themes/Edit",
};
