import { createSlice } from "@reduxjs/toolkit";

const initialState = { metaSection: {}, section1: {} };

const siteInfoSlice = createSlice({
  name: "siteInfo",
  initialState,
  reducers: {
    setSiteInfo: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const siteInfoActions = siteInfoSlice.actions;
export const siteInfoReducer = siteInfoSlice.reducer;

export const SiteInfoActionTypes = {
  FETCH_SITE_INFO: "SiteInfo/Fetch",
  EDIT_SITE_INFO: "SiteInfo/Edit",
};
