import React from "react";
import AppBarComponent from "./TopBar";
import DrawerComponent from "./SideBar";

const Navbar = () => {
  return (
    <>
      <AppBarComponent />
      <DrawerComponent />
    </>
  );
};

export default Navbar;
