import { call, put, takeLatest } from "redux-saga/effects";
import {
  TermsOfUseActionTypes,
  termsofuseActions,
} from "../store/slices/termsofuseSlice";
import termsofuseService from "../services/TermsOfUseService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch FAQ sectionwise and update redux store as per the api payload
export const fetchTermsOfUseSection = function* (action) {
  try {
    const response = yield call(
      termsofuseService.getTermsOfUseSection,
      action.payload
    );
    const termsofuseResponse = yield get(response, "data");
    yield put(
      termsofuseActions.setTermsOfUseSection({
        sectionName: action.payload.sectionName,
        data: termsofuseResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit about us sectionwise and update redux store as per the api payload
export const editTermsOfUseSection = function* (action) {
  try {
    const response = yield call(
      termsofuseService.editTermsOfUseSection,
      action.payload
    );
    const editedSectionData = yield get(response, "data");
    yield put(
      termsofuseActions.setTermsOfUseSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: editedSectionData.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* termOfUseSaga() {
  yield takeLatest(
    TermsOfUseActionTypes.FETCH_TERMSOFUSE,
    fetchTermsOfUseSection
  );
  yield takeLatest(
    TermsOfUseActionTypes.EDIT_TERMSOFUSE,
    editTermsOfUseSection
  );
}
