import { call, put, takeLatest } from "redux-saga/effects";
import { FaqActionTypes, faqActions } from "../store/slices/faqSlice";
import faqService from "../services/FaqService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch FAQ sectionwise and update redux store as per the api payload
export const fetchFaqSection = function* (action) {
  try {
    const response = yield call(faqService.getFaqSection, action.payload);
    const faqResponse = yield get(response, "data");
    yield put(
      faqActions.setFaqSection({
        sectionName: action.payload.sectionName,
        data: faqResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit about us sectionwise and update redux store as per the api payload
export const editFaqSection = function* (action) {
  try {
    const response = yield call(faqService.editFaqSection, action.payload);
    const editedSectionData = yield get(response, "data");
    yield put(
      faqActions.setFaqSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: editedSectionData.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* faqSaga() {
  yield takeLatest(FaqActionTypes.FETCH_FAQ, fetchFaqSection);
  yield takeLatest(FaqActionTypes.EDIT_FAQ, editFaqSection);
}
