import { call, put, takeLatest } from "redux-saga/effects";
import {
  ServiceActionTypes,
  serviceActions,
} from "../store/slices/serviceSlice";
import servicesService from "../services/ServicesService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch service sectionwise and update redux store as per the api payload
export const fetchServiceSection = function* (action) {
  try {
    const response = yield call(
      servicesService.getServiceSection,
      action.payload
    );
    const serviceResponse = yield get(response, "data");
    yield put(
      serviceActions.setServiceSection({
        sectionName: action.payload.sectionName,
        data: serviceResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to add/edit services
export const editServiceSection = function* (action) {
  try {
    const response = yield call(
      servicesService.editServiceSection,
      action.payload
    );
    const successMessage = yield get(response, "data");
    yield put(
      serviceActions.setServiceSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: successMessage.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* serviceSaga() {
  yield takeLatest(ServiceActionTypes.FETCH_SERVICE, fetchServiceSection);
  yield takeLatest(ServiceActionTypes.EDIT_SERVICE, editServiceSection);
}
