import { createSlice } from '@reduxjs/toolkit';

// Initial state for alert slice
const initialState = {
  type: '',
  message: '',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    clearAlert: (state, action) => {
      state.type = initialState.type;
      state.message = initialState.message;
    },
  },
});

export const alertActions = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
