import { createSlice } from "@reduxjs/toolkit";

// Initial state for image slice
const initialState = {
  images: {},
};

const imageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setImages: (state, action) => {
      // state.images = action.payload;
      const { payload } = action;
      const { apiEndpoint, data } = payload;

      if (apiEndpoint === "GetImage" && !Array.isArray(data)) {
        state[apiEndpoint] = [data];
      } else {
        state[apiEndpoint] = data;
      }
    },
  },
});

export const imageActions = imageSlice.actions;
export const imageReducer = imageSlice.reducer;

export const ImageActionTypes = {
  HANDLE_IMAGES: "Image/HandleImages",
};
