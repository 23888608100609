import { call, put, takeLatest } from "redux-saga/effects";
import {
  ProjectActionTypes,
  projectActions,
} from "../store/slices/projectSlice";
import projectService from "../services/ProjectService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch projects sectionwise and update redux store as per the api payload
export const fetchProjectSection = function* (action) {
  try {
    const response = yield call(
      projectService.getProjectSection,
      action.payload
    );
    const projectResponse = yield get(response, "data");
    yield put(
      projectActions.setProjectSection({
        sectionName: action.payload.sectionName,
        data: projectResponse.Title_Section2
          ? projectResponse
          : projectResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit projects sectionwise and update redux store as per the api payload
export const editProjectSection = function* (action) {
  try {
    const response = yield call(
      projectService.editProjectSection,
      action.payload
    );
    const successMessage = yield get(response, "data");
    yield put(
      projectActions.setProjectSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: successMessage.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* projectSaga() {
  yield takeLatest(ProjectActionTypes.FETCH_PROJECT, fetchProjectSection);
  yield takeLatest(ProjectActionTypes.EDIT_PROJECT, editProjectSection);
}
