import { call, put, takeLatest } from "redux-saga/effects";
import {
  CareersActionTypes,
  careersActions,
} from "../store/slices/careersSlice";
import careersService from "../services/CareersService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch careers sectionwise and update redux store as per the api payload
export const fetchCareersSection = function* (action) {
  try {
    const response = yield call(
      careersService.getCareersSection,
      action.payload
    );
    const caareersResponse = yield get(response, "data");
    yield put(
      careersActions.setCareersSection({
        sectionName: action.payload.sectionName,
        data:
          caareersResponse.CareerDescription1_Section2 ||
          caareersResponse.SectionTitle_Section3
            ? caareersResponse
            : caareersResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit careers sectionwise and update redux store as per the api payload
export const editCareersSection = function* (action) {
  try {
    const response = yield call(
      careersService.editCareersSection,
      action.payload
    );
    const editedSectionData = yield get(response, "data");
    yield put(
      careersActions.setCareersSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: editedSectionData.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* careersSaga() {
  yield takeLatest(CareersActionTypes.FETCH_CAREERS, fetchCareersSection);
  yield takeLatest(CareersActionTypes.EDIT_CAREERS, editCareersSection);
}
