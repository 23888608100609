import { createSlice } from "@reduxjs/toolkit";

// Initial state for service slice
const initialState = {
  section1: {},
  section2: {},
};

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServiceSection: (state, action) => {
      const { payload } = action;
      const { sectionName, data } = payload;

      if (sectionName === "section2" && !Array.isArray(data)) {
        state[sectionName] = [data];
      } else {
        state[sectionName] = data;
      }
    },
  },
});

export const serviceActions = serviceSlice.actions;
export const serviceReducer = serviceSlice.reducer;

export const ServiceActionTypes = {
  FETCH_SERVICE: "Service/Fetch",
  EDIT_SERVICE: "Service/Edit",
};
