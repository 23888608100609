import { call, put, takeLatest } from "redux-saga/effects";
import { BlogsActionTypes, blogsActions } from "../store/slices/blogsSlice";
import blogsService from "../services/BlogsService";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";

// saga handler to fetch blogs sectionwise and update redux store as per the api payload
export const fetchBlogsSection = function* (action) {
  try {
    const response = yield call(blogsService.getBlogsSection, action.payload);
    const blogsResponse = yield get(response, "data");
    yield put(
      blogsActions.setBlogsSection({
        sectionName: action.payload.sectionName,
        data: blogsResponse.Title_Section2
          ? blogsResponse
          : blogsResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit blogs sectionwise and update redux store as per the api payload
export const editBlogsSection = function* (action) {
  try {
    const response = yield call(blogsService.editBlogsSection, action.payload);
    const editedSectionData = yield get(response, "data");
    yield put(
      blogsActions.setBlogsSection({
        sectionName: action.payload.sectionName,
        data: {},
      })
    );
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: editedSectionData.Message,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* blogsSaga() {
  yield takeLatest(BlogsActionTypes.FETCH_BLOGS, fetchBlogsSection);
  yield takeLatest(BlogsActionTypes.EDIT_BLOGS, editBlogsSection);
}
