import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import { alertActions } from "../store/slices/alertSlice";
import { ALERT_TYPES } from "../config/constants";
import {
  contactUsActions,
  ContactUsActionTypes,
} from "../store/slices/contactUsSlice";
import contactUsService from "../services/ContactUsService";

// saga handler to fetch contact us sectionwise and update redux store as per the api payload
export const fetchContactUsSection = function* (action) {
  try {
    const response = yield call(
      contactUsService.getContactUsSection,
      action.payload
    );
    const faqResponse = yield get(response, "data");
    yield put(
      contactUsActions.setContactUsSection({
        sectionName: action.payload.sectionName,
        data: faqResponse?.Email_ContactUs
          ? faqResponse
          : faqResponse.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

// saga handler to edit contact us sectionwise and update redux store as per the api payload
export const editContactUsSection = function* (action) {
  try {
    const response = yield call(
      contactUsService.editContactUsSection,
      action.payload
    );
    const editedSectionData = yield get(response, "data");
    // Assuming the response contains the updated section data
    yield put(
      contactUsActions.setContactUsSection({
        sectionName: action.payload.sectionName,
        data: editedSectionData.sectionData,
      })
    );
    // Assuming you want to show a success alert upon successful edit
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.SUCCESS,
        message: "Updated Successfully.",
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    yield put(
      alertActions.createAlert({
        type: ALERT_TYPES.ERROR,
        message: errorMessage,
      })
    );
  }
};

export default function* contactUsSaga() {
  yield takeLatest(
    ContactUsActionTypes.FETCH_CONTACT_US,
    fetchContactUsSection
  );
  yield takeLatest(ContactUsActionTypes.EDIT_CONTACT_US, editContactUsSection);
}
