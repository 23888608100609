/**
 * To check input string is valid JSON or not
 *
 * @param {string} input -input to check is valid JSON or not
 * @returns boolean - true, if valid else false
 */
export const isJson = (input) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

// Function to rename a field in all objects using plain JavaScript map
export const renameArrField = (array, oldFieldName, newFieldName) => {
  return array.map((obj) => {
    if (obj.hasOwnProperty(oldFieldName)) {
      const { [oldFieldName]: omit, ...rest } = obj; // Omit oldFieldName
      return {
        ...rest, // Spread the remaining properties
        [newFieldName]: obj[oldFieldName], // Add the new field
      };
    }
    return obj; // If no modification needed, return the original object
  });
};
