import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Drawer, DrawerContainer, DrawerHeader } from "./NavBar.styled";
import { sideBarData } from "./NavBarConfig";
import { Link } from "react-router-dom";
import logoBackend from "../../styles/footerLogo.svg";

const SideBar = () => {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const handleClick = (index) => {
    setOpenItemIndex(index === openItemIndex ? null : index);
  };

  return (
    <DrawerContainer>
      <Drawer variant="permanent" className="LeftBar">
        <DrawerHeader className="sidebarHeader">
          <img src={logoBackend} className="sidebarLogo" alt="" />
        </DrawerHeader>
        <List className="LeftBarMenu">
          {sideBarData.map((item, index) => (
            <React.Fragment key={item.label}>
              <ListItem disablePadding>
                <Link
                  to={item.children ? null : item.href}
                  onClick={() => handleClick(index)}
                  className="menuItems"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </Link>
              </ListItem>
              {openItemIndex === index && item.children && (
                <List component="div" disablePadding>
                  {item.children.map((childItem) => (
                    <Link
                      to={childItem.href}
                      key={childItem.label}
                      className="childrenMenuItems"
                    >
                      <ListItemButton className="childrenListItems">
                        <ListItemText primary={childItem.label} />
                      </ListItemButton>
                    </Link>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </DrawerContainer>
  );
};

export default SideBar;
