import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class BlogsService {
  // POST request to fetch Blog page section wise
  async getBlogsSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "Blogs",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request to create BlogsSection
  async editBlogsSection(payload) {
    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.BLOGS}/${payload.sectionName}`,
      {
        ...payload.formFields,
      }
    );
    return response;
  }
}

const blogsService = new BlogsService();
export default blogsService;
