import { createSlice } from '@reduxjs/toolkit';

// Initial state for projects slice
const initialState = {
  section1: {},
  section2: {},
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const projectActions = projectSlice.actions;
export const projectReducer = projectSlice.reducer;

export const ProjectActionTypes = {
  FETCH_PROJECT: 'Project/Fetch',
  EDIT_PROJECT: 'Project/Edit',
};
